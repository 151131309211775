import { Storage } from '@ionic/storage'

const getStorage = async () => {
    const store = new Storage()
    await store.create()

    return store
}

const getStorageKey = async (key) => {
    const store = await getStorage()
    return await store.get(key)
}

const setStorageKey = async (key, value) => {
    const store = await getStorage()
    await store.set(key, value)
}

const deleteStorageKey = async (key) => {
    const store = await getStorage()
    await store.remove(key)
}

const deleteCompleteStorage = async () => {
    const store = await getStorage()
    await store.clear()
}

export { getStorageKey, setStorageKey, deleteStorageKey, deleteCompleteStorage }