import { defineStore } from 'pinia'
import { computed, ref } from "vue"
import axios from "axios"
import { deleteStorageKey, getStorageKey, setStorageKey } from "../storage"
import { useSystemStore } from "@/store/SystemStore";

export const useUserStore = defineStore('user', () => {
    const token = ref(null)
    const user = ref(null)

    const systemStore = useSystemStore()

    const isAuthenticated = computed(() => {
        return token.value !== null
    })

    const isAdmin = computed(() => {
        return user.value !== null && user.value.roles.indexOf('admin') > -1
    })

    const getUserdata = computed(() => {
        return user.value
    })

    const checkSession = async () => {
        const userToken = await getStorageKey('userToken')
        if(userToken !== null) {
            token.value = userToken
            await systemStore.startLoading("Deine Daten werden geladen ...")
            const check = await getUser()
            if(check) {
                systemStore.stopLoading()
                return true
            }
        }

        return false
    }

    const getUser = async () => {
        try {
            const response = await axios.get('/api/me').catch(() => {
                return false
            })

            if(response === false) {
                await clearSession()
                return false
            }

            user.value = response.data.data.user
            return response.data.success
        } catch {
            return false
        }
    }

    const checkLogin = async (username, password) => {
        const response = await axios.post('/api/login', { username, password })
        if(response.data.success) {
            token.value = response.data.data.token
            await setStorageKey('userToken', token.value)
            return true
        }

        return false
    }

    const checkTelegramLogin = async (data) => {
        const response = await axios.post('/api/login/telegram', data)
        if(response.data.success) {
            token.value = response.data.data.token
            await setStorageKey('userToken', token.value)
            return true
        }

        return false
    }

    const changePassword = async (password, passwordConfirm) => {
        const response = await axios.post('/api/password', { password, passwordConfirm })
        if(response.data.success) {
            return true
        }

        return false
    }

    const clearSession = async () => {
        await deleteStorageKey('userToken')
        token.value = null
        user.value = null
    }

    const logout = async () => {
        await axios.get('/api/logout')
        await clearSession()
    }

    return { token, user, isAuthenticated, isAdmin, getUserdata, checkSession, changePassword, checkLogin, checkTelegramLogin, clearSession, getUser, logout }
})