<template>
  <ion-app>
    <ion-page v-show="loading">
      <ion-content :fullscreen="true" class="ion-text-center">
        <div id="logo-loading" class="ion-margin-top">
          <div class="logo">
            <img src="/assets/icon/icon.png" alt="Logo">
          </div>
        </div>
        <div v-html="loadingMessage"></div>
      </ion-content>
    </ion-page>
    <ion-split-pane v-show="!loading" content-id="main-content">
        <ion-menu content-id="main-content" type="overlay">
          <ion-content>
            <ion-list id="list">
              <div id="logo">
                <div class="logo">
                  <img src="/assets/icon/icon.png" alt="Logo">
                </div>
                <div class="info">
                  <ion-list-header>ScopoTrading</ion-list-header>
                  <ion-note v-if="isAuthenticated && getUserdata">
                    Hallo {{ getUserdata.name }}
                  </ion-note>
                  <ion-note v-else>Hallo Gast</ion-note>
                </div>
              </div>

              <ion-menu-toggle :auto-hide="false" v-for="(page, index) in appPages" :key="index">
                <ion-item
                  v-if="page.name !== undefined"
                  @click="selectedIndex = index"
                  router-direction="root"
                  lines="none"
                  class="hydrated"
                  :router-link="{ 'name': page.name }"
                  :button="true"
                  :detail="true"
                  :class="{ selected: selectedIndex === index }"
                >
                  <ion-icon slot="start" :icon="page.icon"></ion-icon>
                  <ion-text>{{ page.title }}</ion-text>
                </ion-item>
                <ion-item-divider v-else class="ion-margin-top ion-margin-bottom"></ion-item-divider>
              </ion-menu-toggle>

            </ion-list>
          </ion-content>
        </ion-menu>
        <ion-router-outlet id="main-content"></ion-router-outlet>
      </ion-split-pane>
  </ion-app>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'

import {
  IonApp,
  IonPage,
  IonContent,
  IonIcon,
  IonItem,
  IonText,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane,
  IonItemDivider
} from '@ionic/vue'

import {
  serverOutline,
  peopleOutline,
  logoBitcoin,
  keyOutline,
  logOutOutline,
  createOutline,
  keypadOutline
} from 'ionicons/icons'

import { storeToRefs } from "pinia";
import { useUserStore } from "@/store/UserStore";
import { useSystemStore } from "@/store/SystemStore";
import { useRoute } from "vue-router";

const route = useRoute()

const userStore = useUserStore()
const { isAuthenticated, isAdmin, getUserdata } = storeToRefs(userStore)

const systemStore = useSystemStore()
const { loading, loadingMessage } = storeToRefs(systemStore)

const selectedIndex = ref(0);
const appPages = computed(() => {
  if(isAuthenticated.value) {
    const routes = []
    routes.push({
      title: 'Trades',
      name: 'trades',
      icon: serverOutline
    })

    routes.push({
      title: 'Mitglieder',
      name: 'members',
      icon: peopleOutline
    })

    routes.push({
      title: 'Topcoins',
      name: 'topcoins',
      icon: logoBitcoin
    })

    routes.push({})

    if(isAdmin.value) {
      routes.push({
        title: 'Trade erstellen',
        name: 'admin.create-trade',
        icon: createOutline
      })
      routes.push({})
    }

    routes.push({
      title: 'Passwort ändern',
      name: 'password-change',
      icon: keyOutline
    })

    routes.push({
      title: 'abmelden',
      name: 'logout',
      icon: logOutOutline
    })

    return routes
  }

  return [
    {
      title: 'Login',
      name: 'login',
      icon: keypadOutline
    }
  ]
})

onMounted(async () => {
  appPages.value.forEach((page, index) => { if(page.name === route.name) { selectedIndex.value = index } })
})
</script>

<style scoped>
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-text {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>
