import { createRouter, createWebHistory } from '@ionic/vue-router';
import { useUserStore } from "@/store/UserStore";

const routes = [
  {
    path: '',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import ('../views/UserLogin.vue')
  },
  {
    path: '/password-lost',
    name: 'password-lost',
    component: () => import ('../views/PasswordLost.vue')
  },
  {
    path: '/trade/index',
    name: 'trades',
    meta: { requiresAuth: true },
    component: () => import ('../views/TradeList.vue')
  },
  {
    path: '/trade/:id',
    name: 'trade',
    meta: { requiresAuth: true },
    component: () => import ('../views/Trade.vue')
  },
  {
    path: '/member/index',
    name: 'members',
    meta: { requiresAuth: true },
    component: () => import ('../views/MemberList.vue')
  },
  {
    path: '/topcoin/index',
    name: 'topcoins',
    meta: { requiresAuth: true },
    component: () => import ('../views/TopcoinList.vue')
  },
  {
    path: '/user/password-change',
    name: 'password-change',
    meta: { requiresAuth: true },
    component: () => import ('../views/UserPasswordChange.vue')
  },
  {
    path: '/admin/create-trade',
    name: 'admin.create-trade',
    meta: { requiresAuth: true, isAdmin: true },
    component: () => import ('../views/Admin/CreateTrade.vue')
  },
  {
    path: '/admin/edit-trade/:id',
    name: 'admin.edit-trade',
    meta: { requiresAuth: true, isAdmin: true },
    component: () => import ('../views/Admin/CreateTrade.vue')
  },
  {
    path: '/user/logout',
    name: 'logout',
    meta: { requiresAuth: true },
    component: () => import ('../views/UserLogout.vue')
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeResolve(async to => {
  const userStore = useUserStore()
  const checkSession = await userStore.checkSession()
  if(to.meta.requiresAuth) {
    if(checkSession === false && userStore.isAuthenticated === false) {
      router.push({ name: 'login' })
    } else if(to.meta.isAdmin && !userStore.isAdmin) {
      router.push({ name: 'trades' })
    }
  } else {
    if(checkSession) {
      router.push({ name: 'trades' })
    }
  }
})

export default router
