import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import router from './router/index.js'

import { IonicVue, toastController } from '@ionic/vue'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './theme/layout.css'

import './axiosConfig.js'
//import './registerServiceWorker'

const pinia = createPinia()
const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(pinia)

app.provide('formatPrice', (price, minDigits = 5) => {
    return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: minDigits
    }).format(price)
})

app.provide('toastMessage', async (message, color = "success", duration = 1500, position = "top") => {
    const toast = await toastController.create({
        message,
        duration,
        position,
        color
    });

    await toast.present();
})

router.isReady().then(() => {
  app.mount('#app')
})