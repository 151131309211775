import { defineStore } from 'pinia'
import { ref } from "vue"
import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;
export const useSystemStore = defineStore('system', () => {
    const defaultLoadingMessage = "App wird geladen ..."

    const version = ref(process.env.VUE_APP_VERSION)
    const loading = ref(false)
    const loadingMessage = ref(defaultLoadingMessage)

    const appVersion = async () => {
        const response = await axios.get(API_URL + '/app/version')
        return response.data.data.version
    }

    const startLoading = (message = null) => {
        return new Promise((resolve) => {
            loading.value = true
            loadingMessage.value = message ?? defaultLoadingMessage

            window.setTimeout(() => {
                resolve()
            }, 500)
        })
    }

    const stopLoading = () => {
        return new Promise((resolve) => {
            loading.value = false
            loadingMessage.value = defaultLoadingMessage
            resolve()
        })
    }

    return { version, loading, loadingMessage, startLoading, stopLoading, appVersion }
})