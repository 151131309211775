import axios from "axios"
import { useUserStore } from "@/store/UserStore"
import { toastController } from '@ionic/vue'
import { useSystemStore } from "@/store/SystemStore"

axios.defaults.headers.post['Content-Type'] = 'application/json'

axios.interceptors.request.use(config => {
    const userStore = useUserStore()
    if(userStore.token !== "") {
        config.headers['auth-token'] = userStore.token
    }

    config.baseURL = process.env.VUE_APP_API_URL
    return config
})

axios.interceptors.response.use(response => {
    return response;
},
async () => {
    const userStore = useUserStore()
    const systemStore = useSystemStore()

    await systemStore.startLoading("Du wirst ausgeloggt ...")
    await userStore.clearSession()

    const toast = await toastController.create({
        message: "Session ist abgelaufen",
        position: "top",
        color: "danger",
        duration: 2000
    });

    await toast.present();
    await systemStore.stopLoading()
})